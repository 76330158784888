<template>
	<div class="tcontainer">
		<Toast />
		
		<form ref="pform">
			<div class="clearfix ttitle">
				<label class="pull-right" style="margin-right: 10px; margin-top: 15px">
					<input type="radio" @change="selectPrefer" name="prefer" :value="0">
					Any Doctor
				</label>
				<div class="title">Manage Neurologist</div>
			</div>

			<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
				:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
				:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
				@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">

				<Column field="name" header="Name" filterField="name" :sortable="true">
					<template #filter="{ filterModel, filterCallback }">
						<div>
							<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
								class="form-control" placeholder="Name" />
						</div>
					</template>
					<template #body="{ data }">
						<div class="tdata">
							{{ data['name'] }}
						</div>
					</template>
				</Column>
				
				<Column field="registration_no" header="Registration No" filterField="registration_no" :sortable="true">
					<template #filter="{ filterModel, filterCallback }">
						<div>
							<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
								class="form-control" placeholder="Registration No" />
						</div>
					</template>
					<template #body="{ data }">
						<div class="tdata">
							{{ data['registration_no'] }}
						</div>
					</template>
				</Column>
				<Column field="degree" header="Degree" filterField="degree" :sortable="true">
					<template #filter="{ filterModel, filterCallback }">
						<div>
							<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()"
								class="form-control" placeholder="Degree" />
						</div>
					</template>
					<template #body="{ data }">
						<div class="tdata">
							{{ data['degree'] }}
						</div>
					</template>
				</Column>
				<Column header="Prefer">
					<template #body="{ data }">
						<div class="tdata options">
							<label>
								<input type="radio" @change="selectPrefer" name="prefer" :value="data.id">
								Prefer
							</label>
						</div>
					</template>
				</Column>

				<Column header="Options &nbsp; &nbsp; &nbsp;">
					<template #body="{ data }">
						<div class="tdata options">
							<button class="btn btn-sm btn-outline-info" type="button" @click="showCertificates(data)">
								<i class="fa fa-certificate"></i>
							</button>
						</div>
					</template>
				</Column>

			</DataTable>

		</form>

		<div class="modal fade" ref="certificateModal" tabindex="-1">
			<div class="modal-dialog modal-xl">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Certificates</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<div v-if="certificate1">
							<img :src="certificate1" alt="" style="display: block; margin: auto; max-width: 100%;">
						</div>
						<div v-if="certificate2">
							<img :src="certificate2" alt="" style="display: block; margin: auto; max-width: 100%;">
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Toast from 'primevue/toast';
import { Modal } from 'bootstrap';

export default {
	components: {
		Toast,
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			saving: false,
			lazyParams: {},

			certificateModal: null,
			certificate1: '',
			certificate2: '',


			filters: {
				'id': { value: '', matchMode: 'like' },
				'name': { value: '', matchMode: 'like' },
				'email': { value: '', matchMode: 'like' },
				'password': { value: '', matchMode: 'like' },
				'phone': { value: '', matchMode: 'like' },
				'address': { value: '', matchMode: 'like' },
				'joining_date': { value: '', matchMode: 'like' },
				'registration_no': { value: '', matchMode: 'like' },
				'degree': { value: '', matchMode: 'like' },
				'certificate_image_1': { value: '', matchMode: 'like' },
				'certificate_image_2': { value: '', matchMode: 'like' },
				'signature_image': { value: '', matchMode: 'like' },
				'is_active': { value: '', matchMode: 'like' },
				'total_accepted_reports': { value: '', matchMode: 'like' },
				'total_review_reports': { value: '', matchMode: 'like' },
				'center_ids': { value: '', matchMode: 'like' },
				'bank_details': { value: '', matchMode: 'like' },
				'due_amount': { value: '', matchMode: 'like' },
			},
			center: {},
		}
	},
	mounted() {
		this.certificateModal = new Modal(this.$refs.certificateModal);

		this.get("center/load-center").then(res => {
			this.center = res;
			setTimeout(() => {
				if(this.$refs.pform) this.$refs.pform.prefer.value = res.prefer_neurologist_id;
			}, 100)
			setTimeout(() => {
				if(this.$refs.pform) this.$refs.pform.prefer.value = res.prefer_neurologist_id;
			}, 200)
			setTimeout(() => {
				if(this.$refs.pform) this.$refs.pform.prefer.value = res.prefer_neurologist_id;
			}, 500)
		});

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();
	},
	methods: {
		async selectPrefer() {
			await this.post('center/save-prefer-neurologist', {
				prefer_neurologist_id: this.$refs.pform.prefer.value
			})
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Prefer neurologist saved successfully', life: 3000 });
		},

		showCertificates(d) {
			this.certificate1 = '';
			this.certificate2 = '';

			if (d.certificate_image_1) this.certificate1 = this.apiOrigin + '/../images/neurologist_certificates/' + d.certificate_image_1;
			if (d.certificate_image_2) this.certificate2 = this.apiOrigin + '/../images/neurologist_certificates/' + d.certificate_image_2;

			this.certificateModal.show();
		},

		loadLazyData() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			this.post("center/load-prefer-neurologists", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped></style>
